const { web3 } = require('../web3');
const { env } = require('../config');

const ClientData = require(`./${env}/Client.json`);
const TokenData = require(`./${env}/Token.json`);
const NFTData = require(`./${env}/NFT.json`);

const ClientABI = ClientData['abi'];

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const NFTABI = NFTData['abi'];
const NFTBytecode = NFTData['bytecode'];

module.exports = {
  Token, TokenABI, TokenAddress,
  ClientABI,
  NFTABI, NFTBytecode
};
