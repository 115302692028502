import Kyn from "./Kyn";
import Header from "./Header";
import Footer from "./Footer";
import Profile from "./Landing/profile";
import EventBus from "eventing-bus";
import { useState, useEffect } from "react";
import DonationPopup from "./DonationPopup";
import "owl.carousel/dist/assets/owl.carousel.css";
import together from "../images/together.png";


export default function App() {

  const [donationPopup, showDonationPopup] = useState(false);
  const [kyn, showKyn] = useState(false);
  const [profile, showProfile] = useState(false);

  return (
    <div className="overflow-hidden">
      <Header />

      {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
      {kyn && <Kyn showKyn={showKyn} />}
      {profile && <Profile showProfile={showProfile} />}

      <section className="popular-collection sell mb-10">
            <div className="container">
                <div className="main-head">
                  <h1 className="text-[#18181B] font-normal text-[36px] md:text-[48px] lg:text-[60px] leading-[1.3em] -mb-3">
                      Collection
                  </h1>
                  <p className=" lg:text-[16px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[385px] lg:max-w-full ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>

                <div className="collection-row">

                        <div className="collection-box">
                            <div className="content">
                                <div className="left">
                                    <div className="img-wrap">
                                      <img src={together} />
                                    </div>

                                    <div className="info">
                                        <h3>Creative Art Collection</h3>

                                        <p><span>12 Items</span></p>
                                    </div>
                                </div>
                                
                                
                            </div>

                            <div className="images-wrapper">
                                <img src={together} />

                                <div className="inner-images-wrap">
                                    <img src={together} />

                                    <img src={together} />

                                    <img src={together} />
                                </div>
                            </div>
                        </div>


                        <div className="collection-box">
                            <div className="content">
                                <div className="left">
                                    <div className="img-wrap">
                                        <img src={together} />
                                    </div>

                                    <div className="info">
                                        <h3>Colorful Abstract</h3>

                                        <p><span>12 Items</span></p>
                                    </div>
                                </div>
                                
                                
                            </div>

                            <div className="images-wrapper">
                                <img src={together} />

                                <div className="inner-images-wrap">
                                    <img src={together} />

                                    <img src={together} />

                                    <img src={together} />
                                </div>
                            </div>
                        </div>


                        <div className="collection-box">
                            <div className="content">
                                <div className="left">
                                    <div className="img-wrap">
                                        <img src={together} />
                                    </div>

                                    <div className="info">
                                        <h3>Modern Art Collection </h3>

                                        <p><span>12 Items</span></p>
                                    </div>
                                </div>
                                
                                
                            </div>

                            <div className="images-wrapper">
                                <img src={together} />

                                <div className="inner-images-wrap">
                                    <img src={together} />

                                    <img src={together} />

                                    <img src={together} />
                                </div>
                            </div>
                        </div>
                </div>

                <div class="btn-wrap pt-16 text-center pattern">
                    <button
                    className="bg-[#18181B] text-white w-[47%] md:w-[180px] h-[62px] rounded-[40px] text-[13px] md:text-[15px] font-medium"
                    >
                      Load more
                    </button>
                </div>
            </div>
      </section> 
      <Footer />
    </div>
  );
}
