import axios from "axios";
import EventBus from "eventing-bus";
import NFT from "../images/NFT.gif";
import { web3 } from "../store/web3";
import { connect } from "react-redux";
import { toFixed } from "../store/helper";
import Select from "@mui/material/Select";
import ThankYou from "../images/thankyou.svg";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useState, useRef, useEffect } from "react";
import { getKycStatus, setLoader, getAllDonations } from "../store/actions/Auth";
import { ClientABI, Token, TokenAddress } from "../store/contract/index";
import { ApiUrl } from "../store/config"

function Donation(props) {
  const [amount, setAmount] = useState(0);
  const [xdcAmount, setXdcAmount] = useState(0);
  const [activeLink, setActiveLink] = useState(1);
  const [optDonation, setOptDonation] = useState("");
  const [name, setName] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    props.getAllDonations();
  }, []);


  function collectNFT() {
    props.showDonationPopup(false);
  }

  async function handleEditChange(e) {
    let { xdcPrice } = props;
    setAmount(parseFloat(e.target.value));
    let amountAdded = parseFloat(e.target.value);
    let xdcEqual = parseFloat(xdcPrice);
    let xdc = amountAdded / xdcEqual;
    setXdcAmount(xdc);
  }

  async function dTypes(e) {
    setName(e.target.value);
  }

  async function donationSelect(e) {
    setOptDonation(e.target.value);
  }

  async function Donation() {
    try {
      let { donationContracts } = props;
      const from = (await web3.currentProvider.enable())[0];

      if (amount > 0) {
        let weiAmount = web3.utils.toWei(xdcAmount.toString(), "ether");
        let weiAmount1 = web3.utils.toWei(amount.toString(), "ether");

        if (name == "") {
          EventBus.publish("error", `Please select donation type!`);
          return;
        }

        if (optDonation == "") {
          EventBus.publish("error", `Please select donation method!`);
          return;
        }

        let findDonation = donationContracts.filter((item) =>
          item["contractName"].toLowerCase().includes(name.toLowerCase())
        );
        let dContract = findDonation[0]["donationsContract"];
        let contract = new web3.eth.Contract(ClientABI, dContract);

        let balanceWei = await web3.eth.getBalance(from);
        let balanceEther = web3.utils.fromWei(balanceWei, 'ether');
        if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

        if (optDonation == "XDC") {

          if (balanceEther <= xdcAmount) {
            EventBus.publish("error", `Insufficient balance for donation`);
            return
          }

          props.setLoader({
            message: "Donation in Progress...",
            status: true,
          });

          await web3.eth
            .sendTransaction({
              from,
              value: weiAmount,
              to: dContract,
              data: contract.methods
                .donateAndBuyNFT(from, 1, 0)
                .encodeABI(),
            })
            .on("transactionHash", (hash) => {
              console.log(`*******hash = `, hash);
            })
            .on("receipt", async (receipt) => {
              axios.post(`${ApiUrl}/addDonation`, {
                publicAddress: from,
                donationName: name,
                donationAmount: web3.utils.fromWei(weiAmount.toString(), "ether"),
                donationType: "XDC"
              });
              setActiveLink(3);
              EventBus.publish("success", `Donated successfully`);
              setAmount(0);
              props.setLoader({ status: false });
            });
        } else if (optDonation == "CIFI") {
          props.setLoader({
            message: "Approval in Progress...",
            status: true,
          });

          let balance = await Token.methods.balanceOf(from).call();
          if (parseInt(balance) < parseInt(weiAmount1)) {
            props.setLoader({
              message: "Donation Failed...",
              status: false,
            });
            EventBus.publish("error", `Don't have enough CiFi tokens to donate!`);
            return;
          } else {
            await web3.eth
              .sendTransaction({
                from: from,
                to: TokenAddress,
                gas: 5000000,
                data: Token.methods
                  .transfer(dContract, weiAmount1)
                  .encodeABI(),
              })
              .on('transactionHash', hash => {
                console.log(`************** token transfer hash = ${hash}`);
              })
              .on('receipt', async receipt => {
                props.setLoader({
                  message: "Donation in Progress...",
                  status: true,
                });

                await web3.eth
                  .sendTransaction({
                    from,
                    value: 0,
                    to: dContract,
                    data: contract.methods
                      .donateAndBuyNFT(from, 1, weiAmount1)
                      .encodeABI(),
                  })
                  .on("transactionHash", (hash) => {
                    console.log(`*******hash = `, hash);
                  })
                  .on("receipt", async (receipt) => {
                    axios.post(`${ApiUrl}/addDonation`, {
                      publicAddress: from,
                      donationName: name,
                      donationAmount: web3.utils.fromWei(weiAmount1.toString(), "ether"),
                      donationType: "CiFi Token"
                    });
                    setActiveLink(3);
                    EventBus.publish("success", `Donated successfully`);
                    setAmount(0);
                    props.setLoader({ status: false });
                  });
              });
          }
        } else {
          EventBus.publish("error", `Please select donation method!`);
          return;
        }
      } else {
        return EventBus.publish(
          "error",
          `Please Enter Correct XDC Equivalent Amount!`
        );
      }
    } catch (e) {
      console.log("********Error = ", e);
      props.setLoader({
        message: "Donation Not Completed...",
        status: false,
      });
      props.showDonationPopup(false);
      EventBus.publish("error", `Unable To Donate`);
    }
  }

  let { donationNames } = props;

  return (

    <div className="fixed flex items-center justify-center inset-0 bg-dark-blue/[0.7] z-[999]">
      <div
        className="bg-white rounded-xl md:rounded-[32px] relative w-[576px] max-h-screen overflow-y-auto px-4 md:px-8 py-5 md:py-11 overflow-x-hidden"
        ref={wrapperRef}
      >
        <span
          className="absolute top-0 right-0 inline-block cursor-pointer"
          onClick={() => props.showDonationPopup(false)}
        >
          <img src="/images/cross.svg" alt="cross-icon" />
        </span>
        <ul className="grid grid-cols-4 items-center justify-between mt-7 border-b border-[#b2b2b2] text-[#B2B2B2] font-semibold">
          <li
            className={`text-sm text-center pb-[5px] ${activeLink === 1
              ? "text-dark-blue border-b-4  border-[#28B467] "
              : activeLink > 1 && "text-dark-blue border-b-4 border-[#28B467]"
              }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className="m-auto mb-2"
            >
              <path
                id="Icon_awesome-donate"
                data-name="Icon awesome-donate"
                d="M8,13A6.5,6.5,0,1,0,1.5,6.5,6.5,6.5,0,0,0,8,13ZM7.306,3.044V2.519A.517.517,0,0,1,7.825,2h.347a.517.517,0,0,1,.519.519V3.05a2.464,2.464,0,0,1,1.344.481.35.35,0,0,1,.037.534l-.509.484a.352.352,0,0,1-.437.031,1.029,1.029,0,0,0-.556-.159H7.353a.544.544,0,0,0-.509.572.565.565,0,0,0,.378.55l1.947.584a1.958,1.958,0,0,1,1.366,1.878,1.927,1.927,0,0,1-1.847,1.95v.525A.517.517,0,0,1,8.169,11H7.822a.517.517,0,0,1-.519-.519V9.95a2.464,2.464,0,0,1-1.344-.481.35.35,0,0,1-.037-.534l.509-.484a.352.352,0,0,1,.438-.031,1.029,1.029,0,0,0,.556.159H8.641a.544.544,0,0,0,.509-.572.565.565,0,0,0-.378-.55L6.825,6.872A1.958,1.958,0,0,1,5.459,4.994a1.929,1.929,0,0,1,1.847-1.95ZM15,11H13.984A7.574,7.574,0,0,1,11.7,13H13.7a.279.279,0,0,1,.3.25v.5a.279.279,0,0,1-.3.25H2.3a.279.279,0,0,1-.3-.25v-.5A.279.279,0,0,1,2.3,13H4.294a7.612,7.612,0,0,1-2.281-2H1a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V12A1,1,0,0,0,15,11Z"
                fill={
                  activeLink === 1
                    ? "#28B467"
                    : activeLink > 1
                      ? "#28B467"
                      : "#B2B2B2"
                }
              />
            </svg>
            Select
          </li>
          <li
            className={`text-sm text-center pb-[5px] ${activeLink === 2
              ? "text-dark-blue border-b-4 border-[#28B467] "
              : activeLink > 2 && "text-dark-blue border-b-4 border-[#28B467]"
              }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className="m-auto mb-2"
            >
              <path
                id="Icon_awesome-donate"
                data-name="Icon awesome-donate"
                d="M19,14.2V4.6A1.6,1.6,0,0,0,17.4,3H7.8A1.6,1.6,0,0,0,6.2,4.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6A1.6,1.6,0,0,0,19,14.2ZM10.2,11l1.624,2.168L14.2,10.2l3.2,4H7.8ZM3,6.2V17.4A1.6,1.6,0,0,0,4.6,19H15.8V17.4H4.6V6.2Z"
                transform="translate(-3 -3)"
                fill={
                  activeLink === 2
                    ? "#28B467"
                    : activeLink > 2
                      ? "#28B467"
                      : "#B2B2B2"
                }
              />
            </svg>
            Donate
          </li>
          <li
            className={`text-sm text-center pb-[5px] ${activeLink === 3
              ? "text-dark-blue border-b-4 border-[#28B467] "
              : activeLink > 3 &&
              " text-dark-blue border-b-4 border-[#28B467]"
              }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className="m-auto mb-2"
            >
              <path
                id="Icon_material-collections"
                data-name="Icon material-collections"
                d="M19,14.2V4.6A1.6,1.6,0,0,0,17.4,3H7.8A1.6,1.6,0,0,0,6.2,4.6v9.6a1.6,1.6,0,0,0,1.6,1.6h9.6A1.6,1.6,0,0,0,19,14.2ZM10.2,11l1.624,2.168L14.2,10.2l3.2,4H7.8ZM3,6.2V17.4A1.6,1.6,0,0,0,4.6,19H15.8V17.4H4.6V6.2Z"
                transform="translate(-3 -3)"
                fill={
                  activeLink === 3
                    ? "#28B467"
                    : activeLink > 3
                      ? "#28B467"
                      : "#B2B2B2"
                }
              />
            </svg>
            Collect
          </li>
          <li
            className={`text-sm text-center pb-[5px] ${activeLink === 4
              ? "text-dark-blue border-b-4 border-[#28B467]  "
              : activeLink > 4 &&
              " text-dark-blue border-b-4 border-[#28B467]"
              }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="16"
              viewBox="0 0 20 16"
              className="m-auto mb-2"
            >
              <path
                id="Icon_material-confirmation-number"
                data-name="Icon material-confirmation-number"
                d="M23,12V8a2,2,0,0,0-2-2H5A1.991,1.991,0,0,0,3.01,8v4A2,2,0,0,1,3,16v4a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V16a2,2,0,0,1,0-4Zm-9,7.5H12v-2h2ZM14,15H12V13h2Zm0-4.5H12v-2h2Z"
                transform="translate(-3 -6)"
                fill={
                  activeLink === 4
                    ? "#28B467"
                    : activeLink > 4
                      ? "#28B467"
                      : "#B2B2B2"
                }
              />
            </svg>
            Confirm
          </li>
        </ul>
        <div className="pt-3 md:pt-6">
          {activeLink === 1 ? (
            <div>
              <h4 className="mb-4 text-xl md:text-2xl md:font-bold text-dark-blue">
                Select cause to donate
              </h4>

              <FormControl fullWidth>

                <Select
                  className="border-2 border-black"
                  id="demo-simple-select"
                  value={name}
                  label="name"
                  onChange={dTypes}
                >
                  {donationNames.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <button
                className="inline-flex items-center justify-center w-full h-12 mt-6 text-lg font-medium text-white md:h-16 rounded-xl bg-gradient"
                onClick={name !== "" ? () => setActiveLink(2) : () => EventBus.publish("error", `Please select donation type!`)}
              >
                Select
                <img
                  src="/images/arrow-r-white.svg"
                  className="h-4 ml-4"
                  alt="arrow"
                />
              </button>
            </div>
          ) : activeLink === 2 ? (
            <div>
              <h4 className="text-xl md:text-2xl md:font-bold text-dark-blue ">
                How much you wanna donate?
              </h4>
              <div className="relative flex items-center justify-center text-center border-b-2 border-cultured">
                <p className="inline-block px-4 my-4 -mb-4 text-lg bg-white text-dark-blue">
                  Select Donation Method
                </p>
              </div>
              <FormControl fullWidth>

                <Select
                  className="w-full h-12 px-5 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                  id="donation-method"
                  value={optDonation}
                  label="optDonation"
                  onChange={donationSelect}
                >
                  <MenuItem className="p-0" value="XDC">Donate in XDC</MenuItem>
                  <MenuItem className="p-0" value="CIFI">Donate in CiFi</MenuItem>
                </Select>
              </FormControl>

              {
                optDonation == "XDC" ?
                  (<>
                    <div className="relative flex items-center justify-center text-center border-b-2 border-cultured">
                      <p className="inline-block px-4 my-4 -mb-4 text-lg bg-white text-dark-blue">
                        Amount in $USD
                      </p>
                    </div>

                    <input
                      type="number"
                      pattern="[0-9]*"
                      className="w-full h-12 px-8 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                      placeholder="Enter amount manually"
                      onChange={handleEditChange}
                    />

                    <div className="relative flex items-center justify-center text-center border-b-2 border-cultured">
                      <p className="inline-block px-4 my-4 -mb-4 text-lg bg-white text-dark-blue">
                        Equivalent amount in XDC
                      </p>
                    </div>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      className="w-full h-12 px-8 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                      placeholder={toFixed(xdcAmount, "price")}
                      disabled
                    />

                    <button
                      className="inline-flex items-center justify-center w-full h-12 mt-6 text-lg font-bold text-white md:h-16 rounded-xl bg-[#28B467]"
                      onClick={optDonation !== "" ? () => Donation() : () => EventBus.publish("error", `Please select donation method!`)}
                    >
                      Donate Now
                      <img
                        src="/images/arrow-r-white.svg"
                        className="h-4 ml-4"
                        alt="arrow"
                      />
                    </button>
                  </>)
                  : optDonation == "CIFI" ?
                    (<>
                      <div className="relative flex items-center justify-center text-center border-b-2 border-cultured">
                        <p className="inline-block px-4 my-4 -mb-4 text-lg bg-white text-dark-blue">
                          Amount in CiFi
                        </p>
                      </div>

                      <input
                        type="number"
                        pattern="[0-9]*"
                        className="w-full h-12 px-8 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                        placeholder="Enter amount manually"
                        onChange={handleEditChange}
                      />

                      {/* <div className="relative flex items-center justify-center text-center border-b-2 border-cultured">
                      <p className="inline-block px-4 my-4 -mb-4 text-lg bg-white text-dark-blue">
                        Equivalent amount in XDC
                      </p>
                    </div>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      className="w-full h-12 px-8 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                      placeholder={toFixed(xdcAmount, "price")}
                      disabled
                    /> */}

                      <button
                        className="inline-flex items-center justify-center w-full h-12 mt-6 text-lg font-bold text-white md:h-16 rounded-xl bg-[#28B467]"
                        onClick={optDonation !== "" ? () => Donation() : () => EventBus.publish("error", `Please select donation method!`)}
                      >
                        Donate Now
                        <img
                          src="/images/arrow-r-white.svg"
                          className="h-4 ml-4"
                          alt="arrow"
                        />
                      </button>
                    </>) :
                    <></>
              }
            </div>
          ) : activeLink === 3 ? (
            <div>
              <h4 className="text-xl md:text-2xl md:font-bold text-dark-blue ">
                Collect your NFT
              </h4>
              <div className="h-[350px] md:h-[463px] w-full md:mt-6">
                <img
                  src={NFT}
                  alt="NFT"
                  className="object-contain w-full h-full"
                />{" "}
              </div>
              <button
                className="inline-flex items-center justify-center w-full h-12 mt-3 text-lg font-bold text-white md:h-16 md:mt-6 rounded-xl bg-[#28B467]"
                onClick={() => setActiveLink(4)}
              >
                Collect
                <img
                  src="/images/arrow-r-white.svg"
                  className="h-4 ml-4"
                  alt="arrow-r"
                />
              </button>
            </div>
          ) : (
            activeLink === 4 && (
              <div>
                <div className="py-8 md:py-[100px] text-center">
                  <img src={ThankYou} alt="thank-you" className="mx-auto" />
                  <h4 className="mt-10 text-xl font-bold md:text-3xl text-dark-blue">
                    Thank you for your Donation!
                  </h4>
                </div>
                <button
                  className="inline-flex items-center justify-center w-full h-12 mt-3 text-lg font-bold text-white md:h-16 md:mt-6 rounded-xl bg-[#28B467]"
                  onClick={() => collectNFT()}
                >
                  Confirm
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = { getKycStatus, setLoader, getAllDonations };

const mapStateToProps = ({ Auth }) => {
  let { kycStatus, xdcPrice, donationContracts, donationNames, address } = Auth;
  return { kycStatus, xdcPrice, donationContracts, donationNames, address };
};
export default connect(mapStateToProps, mapDispatchToProps)(Donation);
