import { useState, useEffect } from "react";
import Chart from 'react-apexcharts'
import { connect } from "react-redux";
import Kyn from "../Kyn";
import Header from "../Header";
import Footer from "../Footer";
import NFT from "../../images/NFT.gif";
import { web3 } from "../../store/web3";
import { toFixed } from "../../store/helper";
import DonationPopup from "../DonationPopup";
import { getUser, setLoader } from "../../store/actions/Auth";

function Profile(props) {
  const [donations, setDonations] = useState([]);
  const [totalXDC, setTotalXDC] = useState(0);
  const [totalToken, setTotalToken] = useState(0);
  const [updatedSeries, setUpdatedSeries] = useState([]);
  const [updatedCategories, setUpdatedCategories] = useState([]);
  const [donationPopup, showDonationPopup] = useState(false);
  const [kyn, showKyn] = useState(false);

  let options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2,
      colors: ['#3be3b6'],
    },
    title: {
      text: 'Expense graph',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['transparent'],
        opacity: 1
      },
    },
    xaxis: {
      categories: updatedCategories,
    }
  };

  let series = [{
    name: "Expense",
    data: updatedSeries
  }];

  useEffect(() => {
    if (props.address) props.getUser({ publicAddress: props.address });
  }, [props.address]);

  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      let price = 0;
      let totalXDCValue = 0;
      let totalTokenValue = 0;
      console.log("*** props.userData", props.userData);
      setUpdatedCategories([]);
      setUpdatedSeries([]);
      if (props.userData[0].donations) {
        for (let item of props.userData[0].donations) {
          console.log("***********************");
          if (item['donationType'] == "XDC") {
            totalXDCValue += parseFloat(item['donationAmount']);
          } else if (item['donationType'] == "CiFi Token") {
            totalTokenValue += parseFloat(item['donationAmount']);
          }
          price = parseFloat(item['donationAmount']) + price;
          let timestampInMilliseconds = parseInt(item['time']) * 1000;
          let date = new Date(timestampInMilliseconds);
          let month = date.getMonth();
          let months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          let monthName = months[month];
          setUpdatedCategories((prevCategories) => [...prevCategories, monthName]);
          setUpdatedSeries((prevSeries) => [...prevSeries, toFixed(item['donationAmount'], "price")]);
        }
        setTotalXDC(totalXDCValue);
        setTotalToken(totalTokenValue);
        setDonations(props.userData[0]?.donations);
      }
    }
  }, [props.userData]);


  return (
    <div className="overflow-hidden">
      <Header />
      {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
      {kyn && <Kyn showKyn={showKyn} />}

      <div className="statistics-chart-wrapper">
        <div className="container">
          <h2>Statistics</h2>

          <div className="chart-wrap">
            {options && options['xaxis']['categories'].length > 0 ?
              <>
                <Chart options={options} series={series} type="line" height={350} />

                <div className="course-chart-info">
                  <div className="info">
                    <p>Donation (XDC): {toFixed(totalXDC, "price")}</p>
                  </div>

                  <div className="info">
                    <p>Donation (CiFi Token): {toFixed(totalToken, "price")}</p>
                  </div>

                  <div className="info">
                    <p>Donation Count: {donations.length > 0 ? donations.length : "-"}</p>
                  </div>
                </div>
              </> :
              <div className="course-chart-info">
                <div className="no-data">
                  No data found
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="profile-wrap">
        <div className="container">
          <h2>NFT's</h2>
          <div className="boxes">
            {
              donations.length > 0 && donations.map((item, idx) => (
                <div className="box">
                  <img src={NFT} alt="logo" />
                  <h2>Donation NFT</h2>
                  <p>Name: {item['donationName']}</p>
                  <p>Method: {item['donationType']}</p>
                  <p>Amount: {toFixed(item['donationAmount'], "price")}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

const mapDispatchToProps = { getUser, setLoader };

const mapStateToProps = ({ Auth }) => {
  let { userData, address } = Auth;
  return { userData, address };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
