import axios from 'axios';
import Kyc from "./components/Kyc";
import Collection from "./components/Collection";
import Explore from "./components/Explore";
import Create from "./components/Create";
import MintingEngine from "./components/MintingEngine";
import { web3 } from './store/web3';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { useEffect } from "react";
import Home from './components/Landing/index';
import Profile from './components/Landing/profile';
import Loader from "./components/Loader/index";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import { networkId, message } from "./store/config";
import { getKycStatus, getXdcPrice, setAddress, getAllDonations, logout, login, setXdcPrice } from './store/actions/Auth';

function App(props) {

  const callData = async () => {
    props.getXdcPrice();
    props.getAllDonations();
    const publicAddress = (await web3.currentProvider.enable())[0];
    if (publicAddress) props.getKycStatus({ publicAddress });
  }

  const checkEthereum = async () => {
    if (typeof window.ethereum === 'undefined') {
      EventBus.publish('error', `Please install metamask!`);
      return;
    }

    web3.eth.net.getId(async (err, netId) => {
      if (netId !== networkId) {
        const isNetworkAdded = await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x32', // Chain ID of XDC Testnet
              chainName: 'XDC Network',
              rpcUrls: ['https://erpc.xinfin.network', 'https://earpc.xinfin.network'],
              nativeCurrency: {
                name: 'XDC',
                symbol: 'XDC',
                decimals: 18,
              },
              blockExplorerUrls: ['https://xdcscan.io'],
            },
          ],
        });

        // Auto-connect if the network is added
        if (isNetworkAdded) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x32' }],
          });
        }
      }
      let x = await web3.currentProvider.chainId;
      if (!x) {
        EventBus.publish('error', `Please Install Metamask!!!`);
        return;
      }
    });

    if (typeof window.ethereum !== "undefined") {
      // check network
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) {
          EventBus.publish('info', message);
        }
      });
      const publicAddress = (await web3.currentProvider.enable())[0];
      props.login(publicAddress);
      props.setAddress(publicAddress)
      window.ethereum.on("accountsChanged", accounts => {
        props.logout();
        window.location.reload();
        EventBus.publish("info", "Account has been changed");
      });
      window.ethereum.on("networkChanged", netId => {
        props.logout();
        window.location.reload();
        EventBus.publish("info", "Network has been changed");
      });
    }
  };

  useEffect(() => {
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
    EventBus.on("tokenExpired", () => props.logout());
    callData();
  }, []);

  useEffect(() => {
    checkEthereum();
  });

  let { isLoader } = props;
  const hist = createBrowserHistory();

  return (
    <div>
      <ToastContainer />
      {isLoader && <Loader />}
      <BrowserRouter history={hist}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/minting-engine" element={<MintingEngine />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const mapDispatchToProps = {
  login,
  logout,
  getXdcPrice,
  getKycStatus,
  getAllDonations,
  setXdcPrice,
  setAddress,
};

const mapStateToProps = ({ Auth }) => {
  let { isLoader } = Auth;
  return { isLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
