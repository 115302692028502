import axios from "axios";
import Header from "./Header";
import EventBus from "eventing-bus";
import { web3 } from "../store/web3";
import { connect } from "react-redux";
import { useState, useRef } from "react";
import { getKybStatus, kynSubmit, setLoader } from "../store/actions/Auth";
import arrowup from "../images/arrowup.png";
import close from "../images/close.png";
import { useEffect } from "react";


function Kyn(props) {

  const wrapperRef = useRef(null);
  const [Name, setName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [ein, setEin] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [businessDoc, setBusinessDoc] = useState("");
  const [socialSecurityNo, setSocialSecurityNo] = useState("");
  const [publicAddress, setPublicAddress] = useState("");
  const [kynValue, setKyb] = useState("");
  const [kynStatus, setKybStatus] = useState("");
  const [kyn, showKyn] = useState(false);

  useOutsideAlerter(wrapperRef);

  async function useOutsideAlerter() {
    const address = (await web3.currentProvider.enable())[0];
    if (typeof address !== "undefined") {
      setPublicAddress(address);
    }
  }

  function handleEditChange(e) {
    if ([e.target.name] == "Name") {
      setName(e.target.value);
    } else if ([e.target.name] == "businessEmail") {
      setBusinessEmail(e.target.value);
    } else if ([e.target.name] == "ein") {
      setEin(e.target.value);
    } else if ([e.target.name] == "website") {
      setWebsite(e.target.value);
    } else if ([e.target.name] == "facebook") {
      setFacebook(e.target.value);
    } else if ([e.target.name] == "linkedin") {
      setLinkedin(e.target.value);
    } else if ([e.target.name] == "twitter") {
      setTwitter(e.target.value);
    } else if ([e.target.name] == "businessDoc") {

      setBusinessDoc(e.target.files[0]);
    } else if ([e.target.name] == "socialSecurityNo") {
      setSocialSecurityNo(e.target.value);
    }
  }

  useEffect(() => {
    if (props.kynSubmitted == true) {
      setName("");
      setBusinessEmail("");
      setEin("");
      setWebsite("");
      setFacebook("");
      setLinkedin("");
      setTwitter("");
      setBusinessDoc("");
      setSocialSecurityNo("");
    }
  }, [props.kynSubmitted]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const publicAddress = (await web3.currentProvider.enable())[0];
    if (publicAddress) {
      try {
        let response = await axios({
          method: "post",
          url: `https://server.cifiapp.com/api/v1/getKybStatus`,
          data: { publicAddress },
          headers: { "Content-Type": "application/json" },
        });
        console.log("***** response", response);
        setKyb(response['data']['body']);
        setKybStatus(response['data']['body']['kybStatus']);
      } catch (e) {
        console.error(e.response.data.message);
      }
    }
  }

  function Submit() {
    try {

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (kynStatus !== false) {
        if (kynStatus == "submitted") {
          EventBus.publish("error", `Your KYN has already been submitted!`);
          return;
        } else if (kynStatus == "approved") {
          EventBus.publish("error", `Your KYN has already been approved!`);
          return;
        }
      }

      if (Name == "") {
        EventBus.publish("error", `Please enter non profit title`);
        return;
      }

      if (businessEmail == "") {
        EventBus.publish("error", `Please enter business email`);
        return;
      }

      if (!businessEmail.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        EventBus.publish("error", `Please enter business email`);
        return;
      }

      if (!Name.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter non profit title`);
        return;
      }

      if (!Name.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Non profit title must contain alphabets`);
        return;
      }

      if (socialSecurityNo == "") {
        EventBus.publish("error", `Please enter social security`);
        return;
      }

      if (!socialSecurityNo.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter social security`);
        return;
      }

      if (ein == "") {
        EventBus.publish("error", `Please enter non profit EIN`);
        return;
      }

      if (!ein.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter non profit EIN`);
        return;
      }

      if (businessDoc == "") {
        EventBus.publish("error", `Please upload business document`);
        return;
      }

      if (businessDoc == undefined) {
        EventBus.publish("error", `Please upload business document`);
        return;
      }

      props.setLoader({
        message: "KYN submission in Progress...",
        status: true,
      });

      const formData = new FormData();
      formData.append('document', businessDoc);
      formData.append('email', businessEmail);
      formData.append('publicAddress', publicAddress);
      formData.append('ein', ein);
      formData.append('title', Name);
      formData.append('ownerSocialNo', socialSecurityNo);
      formData.append('website', website);
      formData.append('facebook', facebook);
      formData.append('linkedin', linkedin);
      formData.append('twitter', twitter);
      formData.append('isGreenCross', true);

      axios({
        method: "post",
        url: `https://server.cifiapp.com/api/v1/kybSubmit`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          EventBus.publish("success", `KYN submitted!`);
          props.setLoader({
            message: "KYN submitted...",
            status: false,
          });
          setEin("");
          setName("");
          setWebsite("");
          setFacebook("");
          setLinkedin("");
          setTwitter("");
          setBusinessDoc();
          setBusinessEmail("");
          setSocialSecurityNo("");
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          return EventBus.publish("error", error.response.data.message);
        });

    } catch (error) {
      EventBus.publish("error", error.message);
    }
  }

  console.log("*** kynStatus", kynStatus);
  console.log("*** kynValue", kynValue);

  return (
    <div>
      {kyn && <Kyn showKyn={showKyn} />}
      <Header />
      <div
        className="min-h-screen bg-no-repeat bg-cover popup-wrap"
        style={{ backgroundImage: "url(/images/new-bg.png)" }}
      >
        <div className="container py-10">
          <div className="grid items-center">
            <div className="md:max-w-[645px] mx-auto">
              
              <a href="/">
               <img className="close-modal" onClick={() => showKyn(false)} src={close}/>
              </a>
              
              <h4 className="text-[24px] font-normal md:text-[36px] text-dark-blue text-center">
                Verify For Admin Access
              </h4>
              <div className="py-6 md:py-8 2xl:py-12 px-4 md:px-6 xl:px-8 rounded-[32px] border shadow-2xl border-cultured bg-white mt-4 md:mt-8 xl:mt-8">
                <div className="">
                  <h5 className="mb-4 text-center text-xl font-normal md:text-2xl text-dark-blue">
                    Register your non-profit
                  </h5>
                  {Name !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="Name"
                      placeholder="Non-profit title"
                      onChange={handleEditChange}
                      value={Name}
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="Name"
                      placeholder="Non-profit title"
                      onChange={handleEditChange}
                      value={Name}
                    />
                  )}

                  {businessEmail !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="businessEmail"
                      placeholder="Business Email"
                      onChange={handleEditChange}
                      value={businessEmail}
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="businessEmail"
                      placeholder="Business Email"
                      onChange={handleEditChange}
                      value={businessEmail}
                    />
                  )}

                  {ein !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="ein"
                      placeholder="Business EIN"
                      onChange={handleEditChange}
                      value={ein}
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="ein"
                      placeholder="Business EIN"
                      onChange={handleEditChange}
                      value={ein}
                    />
                  )}

                  {socialSecurityNo !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="socialSecurityNo"
                      placeholder="Owner social security number"
                      onChange={handleEditChange}
                      value={socialSecurityNo}
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      name="socialSecurityNo"
                      placeholder="Owner social security number"
                      onChange={handleEditChange}
                      value={socialSecurityNo}
                    />
                  )}

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="website"
                    placeholder="Website"
                    onChange={handleEditChange}
                    value={website}
                  />

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="facebook"
                    placeholder="Facebook"
                    onChange={handleEditChange}
                    value={facebook}
                  />

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="linkedin"
                    placeholder="Linkedin"
                    onChange={handleEditChange}
                    value={linkedin}
                  />

                  <input
                    type="url"
                    className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                    name="twitter"
                    placeholder="Twitter"
                    onChange={handleEditChange}
                    value={twitter}
                  />

                  {publicAddress !== "" ? (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      placeholder={publicAddress}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      className="w-full h-12 px-4 mb-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none md:mb-6"
                      placeholder="Please connect XDC Pay Wallet!"
                      disabled
                    />
                  )}

                  <div className="form-group filewrap w-full h-12 px-4 text-lg outline-none md:h-14 2xl:h-16 rounded-xl bg-cultured md:px-8 decoration-none">
                    {businessDoc == "" ?
                      <>
                        <span>Business document</span>
                        <div className="upload-btn"><img src={arrowup} alt="Upload File Icon" />Upload PDF File</div>
                        <input
                          type="file"
                          name="businessDoc"
                          placeholder="Business document"
                          accept=".pdf"
                          onChange={handleEditChange}
                        />
                      </> :
                      <>
                        <span>{businessDoc ? (businessDoc['name'] && businessDoc['name'].substring(0, 10) + '...') : "Upload PDF File*"}</span>
                        <div className="upload-btn"><img src={arrowup} alt="Upload File Icon" />Upload PDF File</div>
                        <input
                          type="file"
                          name="businessDoc"
                          placeholder={businessDoc ? (businessDoc['name'] && businessDoc['name'].substring(0, 10) + '...') : "Upload PDF File*"}
                          accept=".pdf"
                          onChange={handleEditChange}
                        />
                      </>
                    }
                  </div>
                </div>
                <button
                  className="inline-flex items-center justify-center w-full h-12 text-lg font-bold text-white md:h-16 mt-8 rounded-xl bg-gradient font-medium"
                  onClick={() => Submit()}
                >
                  Submit KYN
                  <img
                    src="/images/arrow-r-white.svg"
                    className="h-4 ml-4"
                    alt="arrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  getKybStatus, kynSubmit, setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { kynSubmitted } = Auth;
  return { kynSubmitted };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kyn);
