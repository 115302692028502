import LogoFooter from "../images/greencrosslogo.png";
import Twitter from "../images/TwitterW.svg";
import Insta from "../images/InstaW.svg";
export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="inner">
          <div className="left">
            <a className="logo-wrap inline-flex items-center text-[#097238] text-lg font-bold" href="/">
              <img
                src={LogoFooter}
                alt="logo"
                className=" mr-[10px] mb-4 md:mb-0"
              />
              GREEN CROSS
            </a>

            <p>The Future of Fundraising.</p>
          </div>
          
          <div className="links-wrapper">
            {/* <div className="wrap">
              <span>Product</span>

              <ul>
                <li><a href="/" target="_blank">Whitepaper</a></li>
                <li><a href="/" target="_blank">Resources</a></li>
                <li><a href="/" target="_blank">Blog</a></li>
                <li><a href="/" target="_blank">Contact</a></li>
              </ul>
            </div> */}

            {/* <div className="wrap">
              <span>App</span>

              <ul>
                <li><a href="https://cifiapp.com/dashboard/swap" target="_blank">Swap</a></li>
                <li><a href="/" target="_blank">Stability Pool</a></li>
                <li><a href="https://cifiapp.com/dashboard/microeconomy/stake-nft" target="_blank">Staking</a></li>
                <li><a href="https://cifiapp.com/dashboard/microeconomy" target="_blank">Microeconomy Tool</a></li>
              </ul>
            </div> */}
          </div>
        </div>

        <div className="copyright">
          <p>Copyright ©2024 <span>Green Cross Donations</span></p>
          {/* <a href="/">Terms & Conditions</a> */}
        </div>
      </div>
    </footer>
  );
}
