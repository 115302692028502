import Kyn from "./Kyn";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { web3 } from "../store/web3";
import { connect } from "react-redux";
import Profile from "./Landing/profile";
import EventBus from "eventing-bus";
import { useState, useEffect } from "react";
import DonationPopup from "./DonationPopup";
import "owl.carousel/dist/assets/owl.carousel.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { networkId, ApiUrl } from "../store/config";
import { NFTABI, NFTBytecode } from "../store/contract";
import { setLoader, getAllDonations } from "../store/actions/Auth";


function CreateNFT(props) {

    useEffect(() => {
        props.getAllDonations();
    }, []);

    const [donationPopup, showDonationPopup] = useState(false);
    const [kyn, showKyn] = useState(false);
    const [profile, showProfile] = useState(false);

    //////////////////////////////////////////////////////////////////////////////

    const [BToken, setBToken] = useState("XDC");
    const [subPrice, setSubPrice] = useState(50);
    const [xrcToken, setxrcToken] = useState("");
    const [tokenUri, settokenUri] = useState("");
    const [tokenName, settokenName] = useState("");
    const [tokenPrice, settokenPrice] = useState("");
    const [tokenSupply, settokenSupply] = useState("");
    const [tokenBanner, settokenBanner] = useState("");
    const [tokenSymbol, settokenSymbol] = useState("");
    const [tokenAddress, settokenAddress] = useState("");
    const [tokenDescription, settokenDescription] = useState("");
    const [tokenWallet, settokenWallet] = useState("");

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "tokenName") {
            settokenName(e.target.value);
        } else if ([e.target.name] == "tokenSymbol") {
            settokenSymbol(e.target.value);
        } else if ([e.target.name] == "tokenUri") {
            let inputValue = e.target.value;
            if (inputValue && !inputValue.startsWith('https://')) {
                inputValue = 'https://' + inputValue;
                settokenUri(inputValue)
            } else {
                settokenUri(e.target.value);
            }

        } else if ([e.target.name] == "tokenDescription") {
            settokenDescription(e.target.value);
        } else if ([e.target.name] == "tokenSupply") {
            if (parseInt(e.target.value) > 0) {
                settokenSupply(parseInt(e.target.value));
            } else {
                settokenSupply("");
            }
        } else if ([e.target.name] == "tokenBanner") {
            settokenBanner(e.target.files[0]);
        } else if ([e.target.name] == "tokenPrice") {
            if (parseFloat(e.target.value) > 0) {
                settokenPrice(parseFloat(e.target.value));
            } else {
                settokenPrice("");
            }
        } else if ([e.target.name] == "tokenWallet") {
            settokenWallet(e.target.value);
        } else if ([e.target.name] == "xrcToken") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setxrcToken(result);
            } else {
                setxrcToken(e.target.value);
            }
        }
    }

    async function getIPFSData(url) {
        return new Promise(async (resolve, reject) => {
            try {
                if (url && !url.endsWith("/")) url = url + "/";
                let data = await fetch(`${ApiUrl}/validateURI`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
                    },
                    body: JSON.stringify({ url: `${url}1` }), // Convert your data to JSON format if needed
                });
                if (parseInt(data.status) == 200) {
                    data = await data.json();
                    return resolve(true);
                }
                if (parseInt(data.status) == 400) {
                    let data = await fetch(`${ApiUrl}/validateURI`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
                        },
                        body: JSON.stringify({ url: `${url}1.json` }), // Convert your data to JSON format if needed
                    });
                    if (parseInt(data.status) == 200) {
                        return resolve(true);
                    } else if (parseInt(data.status) == 400) {
                        let data = await fetch(`${ApiUrl}/validateURI`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
                            },
                            body: JSON.stringify({ url }), // Convert your data to JSON format if needed
                        });
                        if (parseInt(data.status) == 200) {
                            return resolve(true);
                        }
                        else return resolve(false);
                    }
                }
            } catch (e) {
                return resolve(false);
            }
        });
    }

    async function deploy(e) {
        try {

            e.preventDefault();

            let { donationContracts } = props;

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let deployer = (await web3.currentProvider.enable())[0];

            let paymentToken = "";

            if (tokenName == "") {
                EventBus.publish("error", `Please enter token name`);
                return;
            }

            if (!tokenName.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token name`);
                return;
            }

            if (!tokenName.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token name must contain alphabets`);
                return;
            }

            if (tokenSymbol == "") {
                EventBus.publish("error", `Please enter token symbol`);
                return;
            }

            if (!tokenSymbol.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token symbol`);
                return;
            }

            if (!tokenSymbol.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token symbol must contain alphabets`);
                return;
            }

            if (tokenSupply == "") {
                EventBus.publish("error", `Please enter token supply`);
                return;
            }

            if (tokenUri == "") {
                EventBus.publish("error", `Please enter base URI`);
                return;
            }

            if (!tokenUri.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter base URI`);
                return;
            }

            if (!tokenUri.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Base URI must contain alphabets`);
                return;
            }

            let checkUrl = await getIPFSData(tokenUri);
            if (checkUrl == false) return EventBus.publish('error', `Invalid base URI`);

            if (tokenPrice == "") {
                EventBus.publish("error", `Please enter token price`);
                return;
            }

            console.log("*** tokenWallet", tokenWallet);

            if (tokenWallet == "") {
                EventBus.publish("error", `Please enter token wallet`);
                return;
            }

            let findDonation = donationContracts.filter((item) =>
                item["contractName"].toLowerCase().includes(tokenWallet.toLowerCase())
            );

            let dContract = findDonation[0]["donationsContract"];

            if (xrcToken == "0x0000000000000000000000000000000000000000") {
                setxrcToken("0x0000000000000000000000000000000000000000");
                paymentToken = "0x0000000000000000000000000000000000000000";
            } else {

                if (!xrcToken.replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter payment Token`);
                    return;
                }

                if (!xrcToken.match(/^[a-zA-Z0-9]+$/)) {
                    EventBus.publish("error", `Invalid Payment Token Address`);
                    return;
                }

                let code = await web3.eth.getCode(xrcToken.toLowerCase());
                if (code === "0x") {
                    EventBus.publish("error", `Invalid Payment Token Address!`);
                    return;
                } else {
                    paymentToken = xrcToken;
                }
            }

            if (tokenDescription == "") {
                EventBus.publish("error", `Please enter token description`);
                return;
            }

            if (!tokenDescription.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token description`);
                return;
            }

            if (!tokenDescription.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token description must contain alphabets`);
                return;
            }

            if (tokenBanner == "") {
                EventBus.publish("error", `Please upload token banner`);
                return;
            }

            if (tokenBanner == undefined) {
                EventBus.publish("error", `Please upload token banner`);
                return;
            }

            if (deployer == null || deployer == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let from = deployer;
            let output = deployer.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + deployer.substring(3);
            } else {
                from = deployer;
            }

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.setLoader({
                message: "Deployment in Progress...",
                status: true,
            });

            let price = await web3.utils.toWei(tokenPrice.toString(), "ether");

            let tokenType = "XRC721"

            console.log("*** tokenName :: ", tokenName);
            console.log("*** tokenSymbol :: ", tokenSymbol);
            console.log("*** tokenDescription :: ", tokenDescription);
            console.log("*** tokenWallet :: ", dContract);
            console.log("*** tokenSupply :: ", tokenSupply);
            console.log("*** tokenPrice :: ", web3.utils.fromWei(price.toString(), "ether"));
            console.log("*** tokenType :: ", tokenType);
            console.log("*** from :: ", from);
            console.log("*** banner :: ", tokenBanner);
            console.log("*** paymentToken :: ", paymentToken);
            console.log("*** network :: ", parseInt(networkId));

            let contract = new web3.eth.Contract(NFTABI);
            let deploy = await contract.deploy({ data: NFTBytecode, arguments: [tokenName, tokenSymbol, tokenSupply, tokenUri, price, dContract, paymentToken] });

            await deploy.send({ from: deployer })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.setLoader({
                        message: "Contract Deploy...",
                        status: false,
                    });

                    let data = new FormData();
                    data.append('tokenName', tokenName);
                    data.append('tokenSymbol', tokenSymbol);
                    data.append('tokenDescription', tokenDescription);
                    data.append('tokenWallet', dContract);
                    data.append('tokenAddress', receipt['contractAddress']);
                    data.append('tokenSupply', tokenSupply);
                    data.append('tokenPrice', web3.utils.fromWei(price.toString(), "ether"));
                    data.append('tokenType', tokenType);
                    data.append('from', from);
                    data.append('banner', tokenBanner);
                    data.append('paymentToken', paymentToken);
                    data.append('network', parseInt(networkId));

                    axios({
                        method: "post",
                        url: `${ApiUrl}/deployToken`,
                        data: data,
                        headers: { "Content-Type": "multipart/form-data" },
                    })
                        .then(function (response) {
                            console.log(response['data']['message']);
                            EventBus.publish("success", response['data']['message'])
                        })
                        .catch(function (error) {
                            console.log(error['response']['data']['message']);
                            EventBus.publish("error", error['response']['data']['message']);
                        });

                    EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
                    waitFor(1500);
                    settokenAddress(receipt['contractAddress']);
                    settokenName("");
                    settokenSymbol("");
                    settokenUri("");
                    settokenDescription("");
                    settokenSupply("");
                    settokenBanner("");
                    settokenPrice("");
                    settokenWallet("");
                    setxrcToken("");
                    console.log(`************** deploy contract address = `, receipt['contractAddress'])
                });
        } catch (e) {
            console.log(e);
            setxrcToken("");
            props.setLoader({
                message: "Transfer Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Unable To Deploy`);
        }
    };

    async function copiedAddress() {
        EventBus.publish("success", "Contract Address Copied");
    }


    let { donationNames } = props;

    console.log("************ xrcToken :: ", xrcToken);
    console.log("************ tokenWallet :: ", tokenWallet);
    // console.log("************ donationNames :: ", donationNames);
    // console.log("************ donationContracts :: ", donationContracts);

    return (
        <div className="overflow-hidden">
            <Header />

            {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
            {kyn && <Kyn showKyn={showKyn} />}
            {profile && <Profile showProfile={showProfile} />}

            <section className="profile">
                <div class="tab-content">
                    <div className="signin">
                        <form className="create" onSubmit={deploy}>
                            <h2 className="mb-20">Create</h2>

                            <div className="form-group" controlId="Name">
                                <label>Name</label>
                                <input
                                    type="text"
                                    name='tokenName'
                                    value={tokenName}
                                    onChange={handleOnInput}
                                    maxlength="42"
                                    placeholder="Example “MARS NFT”"
                                />
                            </div>

                            <div className="form-group" controlId="Name">
                                <label>Symbol</label>
                                <input
                                    type="text"
                                    name='tokenSymbol'
                                    value={tokenSymbol}
                                    onChange={handleOnInput}
                                    placeholder="Example “$MARS$”"
                                    maxlength="10"
                                />
                            </div>

                            <h2>Banner image</h2>

                            <p>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 500 x 500 recommended.</p>

                            <div className="form-group" controlId="formBasicFame">

                                <div className="upload-file">
                                    <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Drag and drop file here"}</span>

                                    <input
                                        type="file"
                                        name='tokenBanner'
                                        placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}
                                        accept="image/*"
                                        onChange={handleOnInput}
                                    />

                                    <button className="common-btn border-white">Choose File</button>
                                </div>

                                <span>Files supported: PNG, JPEG or JPG. Max 25mb.</span>
                            </div>

                            <div className="form-group" controlId="URL">
                                <label>Base URI*</label>
                                <p>Customize your URL on Marketplace. Must only contain lowercase letters,numbers, and hyphens.</p>
                                {
                                    parseInt(networkId) == 51 ?
                                        <a href="https://dhegch1bc76pc.cloudfront.net/" target="_blank">Click here to generate your Base URI</a>
                                        :
                                        <a href="https://tool.cifiapp.com/" target="_blank">Click here to generate your Base URI</a>
                                }
                                <input
                                    type="text"
                                    name='tokenUri'
                                    value={tokenUri}
                                    onChange={handleOnInput}
                                    maxlength="250"
                                    placeholder="https://marketplace.com/collection/mars-stones"
                                />
                            </div>

                            <div className="form-group" controlId="Price">
                                <label>NFT Price*</label>
                                <p>Enter your NFT price here.</p>
                                <input
                                    type="number"
                                    name='tokenPrice'
                                    value={tokenPrice}
                                    onChange={handleOnInput}
                                    placeholder="Price (example 0.2)"
                                    onWheel={handleOnWheel}
                                />
                            </div>

                            <div className="form-group">
                                <label>Donations Cause*</label>

                                <p>Select the donations cause you want the earnings of this collection to go to.</p>

                                <FormControl fullWidth>
                                    <InputLabel id="donations-cause-tokenWallet">Select Cause</InputLabel>
                                    <Select
                                        className="w-full h-12 px-5 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                                        id="donations-cause"
                                        label="Select Cause"
                                        labelId="payable-token-xrcToken"
                                        value={tokenWallet}
                                        name="tokenWallet"
                                        onChange={handleOnInput}
                                    >
                                        {donationNames.map((item) => (
                                            <MenuItem className="p-0" value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="form-group">
                                <label>Payable Token</label>

                                <p>Select NFT collection minting method.</p>

                                <FormControl fullWidth>
                                    <InputLabel id="payable-token-xrcToken">Select Method</InputLabel>
                                    <Select
                                        className="w-full h-12 px-5 mt-8 text-lg outline-none rounded-xl bg-cultured md:h-16 decoration-none"
                                        labelId="payable-token-xrcToken"
                                        id="payable-token"
                                        label="Select Method"
                                        value={xrcToken}
                                        name="xrcToken"
                                        onChange={handleOnInput}
                                    >
                                        <MenuItem className="p-0" value="0x0000000000000000000000000000000000000000">Users buy NFT in XDC</MenuItem>
                                        <MenuItem className="p-0" value="0x3CB8fb59815A8a69c197fd46b112Dd70EBF0CDEa">Users buy NFT in CiFi</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="form-group" controlId="Name">
                                <label>Total Supply</label>
                                <p>Enter your NFT collection supply here.</p>
                                <input
                                    type="number"
                                    name='tokenSupply'
                                    value={tokenSupply}
                                    onChange={handleOnInput}
                                    onWheel={handleOnWheel}
                                    placeholder="1000000"
                                />
                            </div>

                            <div className="form-group" controlId="Desc">
                                <label>Description</label>
                                <p>Markdown syntax is supported. 0 of 1000 characters used.</p>
                                <textarea
                                    as="textarea"
                                    rows={3}
                                    name='tokenDescription'
                                    value={tokenDescription}
                                    onChange={handleOnInput}
                                    maxlength="1000"
                                    placeholder="Description* (Max 250 Characters)"
                                />
                            </div>
                            <button className="common-btn create-btn" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

const mapDispatchToProps = { setLoader, getAllDonations };

const mapStateToProps = ({ Auth }) => {
    let { kycStatus, donationContracts, donationNames, address } = Auth;
    return { kycStatus, donationContracts, donationNames, address };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNFT);