import { useEffect,useState, useRef } from "react";
import { Veriff } from '@veriff/js-sdk';
import { connect } from "react-redux";
import axios from "axios";
import $ from 'jquery';
import Header from "./Header";
import EventBus from "eventing-bus";
import { web3 } from "../store/web3";
import {ApiUrl} from "../store/config";
import { kycSubmit, getKycStatus } from "../store/actions/Auth";
import PersonalInfo from "../images/greencrosslogo.png";


function Kyc(props) {
  const [publicAddress, setPublicAddress] = useState("");

  const OutsideAlerter = async()=> {
    const address = (await web3.currentProvider.enable())[0];
    if (address) {
      props.getKycStatus({ publicAddress:address });
      setPublicAddress(address);
    }
  }

  useEffect(()=>{
    OutsideAlerter();
  },[])

  useEffect(() => {
    if (props.kycStatus == false && props.address) {
      // if (props.kycStatus == false && props.address && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.address.toLowerCase();
      }
    }
    else if (props.kycStatus == "expired" && props.address) {
      // else if (props.kycStatus == "expired" && props.address && isWhitelist == true) {
      veriffStart();
      $(".veriff-container").prepend(`<label class="veriff-label" id="veriff-label-given-email" htmlfor="givenEmail">Email</label><input type="email" class="veriff-text" id="veriff-given-email" name="givenEmail" required="" placeholder="Email">`);
      var veriffVendorDataInput = document.getElementById("veriff-vendor-data");
      // Check if the element exists before attempting to set its value
      if (veriffVendorDataInput) {
        // Set the value of the input field
        veriffVendorDataInput.value = props.address.toLowerCase();
      }
    }
  }, [props.kycStatus, props.address]);

  async function Submit({ id, email, vendorData, url, status, session }) {
    try {
      let { kycValue } = props;
      if (kycValue.length === 0) {
        axios.post(`${ApiUrl}/kycSubmit`, { id, email, vendorData, url, status, session }).then(function (response) {
          // EventBus.publish("success", `KYC submitted!`);
        })
        .catch(function (error) {
          //handle error
          console.log(error.response.data.message);
          return EventBus.publish("error", error.response.data.message);
        });
        // await props.kycSubmit({ publicAddress, Name, country, taxId, socialSecurityNo });
      } else {
        EventBus.publish("info", `KYC already submitted!`);
      }
    } catch (error) {
      EventBus.publish("error", error.message);
    }
  }

  const veriffStart = async () => {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: process.env.REACT_APP_VERIFF,
      parentId: 'veriff-root',
      onSession: async function (err, response) {
        await waitFor(1500);
        let email = document.getElementById('veriff-given-email').value;
        if (!email) return
        if(response){
          let id = response.verification.id;
          let vendorData = response.verification.vendorData;
          let url = response.verification.url;
          let status = response.verification.status;
          let session = response.verification.sessionToken;
          vendorData = vendorData.toLowerCase();
          if (id && vendorData && url && status && session) {
            Submit({ id, email, vendorData, url, status, session });
            await waitFor(1500);
            window.location.href = response.verification.url;
          }
        }
        else if(err){
          return EventBus.publish("error", "Veriff error");
        }
       
      }
    });
    veriff.mount({
      formLabel: {
        givenName: 'First Name',
        lastName: 'Last Name',
        vendorData: 'Metamask Wallet Address'
      },
    });
  }

  return (
    <div>
      <Header />
      <div
        className="min-h-screen bg-no-repeat bg-cover popup-wrap"
        style={{ backgroundImage: "url(/images/new-bg.png)" }}
      >
        <div className="container py-20">
          <div className="grid items-center grid-cols-1 py-16 gap-y-5 md:grid-cols-2 gap-x-11 2xl:gap-x-20">
            <div>
              {/* <img src={PersonalInfo} alt="" /> */}
            </div>
            <div className="md:max-w-[645px]">
              <h4 className="text-[24px] font-normal md:text-[36px] text-dark-blue">
                Verify For Green Cross Access
              </h4>
              <div className="py-6 md:py-8 2xl:py-12 px-4 md:px-6 xl:px-8 rounded-[32px] border shadow-2xl border-cultured bg-white mt-4 md:mt-8 xl:mt-12">

                {props.kycStatus == false &&
                    <>
                      <h2>Verification</h2>
                      <p className="mb-4 left">Verify your Identity to access the features of Green Cross.</p>
                      <div className="veriff-wrap">
                        <div id='veriff-root' className="edit-add-body col-12"></div>
                      </div>
                    </>
                  }

                  {props.kycStatus == "expired" &&
                    <>
                      <h2>Verification</h2>
                      <p className="mb-4 left">Verify your Identity to access the features of Green Cross.</p>
                      <div className="veriff-wrap">
                        <div id='veriff-root' className="edit-add-body col-12"></div>
                      </div>
                    </>
                  }

                  {props.kycStatus == "created" &&
                    <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                  }
                  {props.kycStatus == "approved" &&
                    <p className="text-center">Verification Already Submitted, It will take 24Hrs to process your verification.</p>
                  }
                  {props.kycStatus == "adminApproved" &&
                    <p className="text-center">Your KYC is approved</p>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  kycSubmit,getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { kycStatus, kycValue, kycSubmitted, address } = Auth;
  console.log("***************** kycStatus",kycStatus);
  return { kycStatus, kycValue, kycSubmitted, address };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kyc);
