import Kyn from "./Kyn";
import Header from "./Header";
import Footer from "./Footer";
import EventBus from "eventing-bus";
import { web3 } from "../store/web3";
import { connect } from "react-redux";
import Profile from "./Landing/profile";
import { useState, useEffect } from "react";
import DonationPopup from "./DonationPopup";
import "owl.carousel/dist/assets/owl.carousel.css";
import { NFTABI, TokenABI } from "../store/contract";
import { networkId } from "../store/config";
import { setLoader, getXRC721 } from "../store/actions/Auth";


function Explore(props) {

    let [txHash, setTxHash] = useState("");
    let [collections, setCollections] = useState([]);
    let [data, setData] = useState({});
    let [minted, setMinted] = useState(0);
    let [price, setPrice] = useState(0);
    const [donationPopup, showDonationPopup] = useState(false);
    const [mintPopup, showMintPopup] = useState(false);
    const [kyn, showKyn] = useState(false);
    const [profile, showProfile] = useState(false);

    useEffect(() => {
        props.getXRC721();
    }, []);

    async function setMarketplaceData() {
        const updatedCollection = await Promise.all(
            props.xrc721Collections.map(async (item) => {
                if (parseInt(item['item']['network']) == parseInt(networkId)) {
                    let tokenSymbol = "XDC";
                    if (item['item']['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                        let contract = new web3.eth.Contract(TokenABI, item['item']['paymentToken']);
                        let symbol = await contract.methods.symbol().call();
                        tokenSymbol = symbol;
                    }
                    return { ...item, tokenSymbol };
                }
                return item;
            })
        );
        setCollections(updatedCollection);
    }

    useEffect(() => {
        if (props.address == null || props.address == undefined) {
            return;
        } else {
            if (props.xrc721Collections.length > 0 && web3) {
                setMarketplaceData();
            }
        }
    }, [props.xrc721Collections, props.address, web3]);

    async function setMarketplaceData() {
        const updatedCollection = await Promise.all(
            props.xrc721Collections.map(async (item) => {
                if (parseInt(item['item']['network']) == parseInt(props.selectDefaultNetwok)) {
                    let tokenSymbol = "XDC";
                    if (item['item']['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                        let contract = new web3.eth.Contract(TokenABI, item['item']['paymentToken']);
                        let symbol = await contract.methods.symbol().call();
                        tokenSymbol = symbol;
                    }
                    return { ...item, tokenSymbol };
                }
                return item;
            })
        );
        setCollections(updatedCollection);
    }

    async function setInstance(item) {
        showMintPopup(true);
        setData(item);
        let contract = new web3.eth.Contract(NFTABI, item['address']);
        let mint = await contract.methods.totalSupply().call();
        setMinted(mint);
        setPrice(item['price']);
    }

    async function mintNFT() {
        try {
            // e.preventDefault();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { address } = props;

            if (1 >= (data['supply'] - minted)) {
                EventBus.publish("error", `Mint amount exceeds available NFTs!`);
                return;
            }

            if (address == null || address == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            console.log("*** balanceWei", balanceWei);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let tokenAmount = 0;

            let sentValue = (parseInt(data['price']) * parseInt(1));
            sentValue = web3.utils.toWei(sentValue.toString(), "ether");

            let price = data['price'];

            console.log("*** price", price);

            if (data['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
                let contractPaymentToken = new web3.eth.Contract(TokenABI, data['paymentToken']);
                let userBalance = await contractPaymentToken.methods.balanceOf(deployer).call();
                userBalance = web3.utils.fromWei(userBalance.toString(), "ether");


                if (parseInt(userBalance) < (parseInt(price) * parseInt(1))) return EventBus.publish("error", `Insufficient token amount`);
                props.setLoader({
                    message: "Approval in Progress...",
                    status: true,
                });

                tokenAmount = parseInt(price) * parseInt(1);
                tokenAmount = web3.utils.toWei(tokenAmount.toString(), "ether");

                console.log("*** tokenAmount", tokenAmount);

                await contractPaymentToken.methods.approve(data['address'], tokenAmount.toString()).send({
                    from: deployer,
                });
                props.setLoader({
                    message: "Approval complete...",
                    status: true,
                });
                sentValue = 0;
            }

            props.setLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = address;
            let output = address.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + address.substring(3);
            } else {
                from = address;
            }

            let contract = new web3.eth.Contract(NFTABI, data['address']);

            console.log("*** sentValue", sentValue);

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: sentValue,
                    to: data['address'],
                    gas: 5000000,
                    data: contract.methods
                        .mint(from, 1, tokenAmount.toString())
                        .encodeABI(),
                })
                .on('transactionHash', hash => {
                    console.log(`************** deploy contract hash = ${hash}`);
                    setTxHash(hash);
                })
                .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                    EventBus.publish("success", `NFT Minted Successfully!`);
                    showMintPopup(false);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Mint Not Completed...",
                status: false,
            });
            showMintPopup(false);
            EventBus.publish("error", `Mint Failed`);
        }
    };


    console.log("******** collections", collections);
    console.log("******** data", data);

    return (
        <div className="overflow-hidden">
            <Header />

            {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
            {mintPopup &&
                <div className="fixed flex items-center justify-center inset-0 bg-dark-blue/[0.7] z-[999]">
                    <div
                        className="bg-white rounded-xl md:rounded-[32px] relative w-[576px] max-h-screen overflow-y-auto px-4 md:px-8 py-5 md:py-11 overflow-x-hidden"
                    >
                        <span
                            className="absolute top-0 right-0 inline-block cursor-pointer"
                            onClick={() => showMintPopup(false)}
                        >
                            <img src="/images/cross.svg" alt="cross-icon" />
                        </span>
                        <div className="minting">
                            <h2>Minting</h2>

                            <p>Mint the NFT of the cause closest to your heart and make a difference.</p>

                            <div className="info">
                                <span>Total number of NFTs</span>
                                <span>{data?.['supply'] ? data?.['supply'] : "-"}</span>
                            </div>

                            <div className="info">
                                <span>Total Minted NFTs</span>
                                <span>{minted ? minted : 0}</span>
                            </div>

                            <div className="info">
                                <span>Price per NFT</span>
                                <span>
                                    {
                                        data['paymentToken'] == undefined || data['paymentToken'] == "0x0000000000000000000000000000000000000000"
                                            ?
                                            <span>{data['price']} XDC</span>
                                            :
                                            <span>{data['price']} CIFI</span>
                                    }
                                </span>
                            </div>

                            <div className="info">
                                <span>Connected address</span>
                                <span>{props.address}</span>
                            </div>

                            <button
                                className="bg-gradient text-white w-[130px] h-12 rounded-[40px] text-[15px] font-medium"
                                onClick={() => mintNFT()}
                            >
                                Mint now
                            </button>
                        </div>
                    </div>
                </div>
            }
            {kyn && <Kyn showKyn={showKyn} />}
            {profile && <Profile showProfile={showProfile} />}

            <section className="explore mb-10">
                <div className="container">
                    <div className="explore-row">
                        <div class="picks">
                            <div className="collection-row">
                                {
                                    collections?.length > 0 &&
                                    collections?.map((item) => (
                                        <div className="collection-box">
                                            <div className="img-wrap">
                                                <img src={item['item']['banner']} alt=" " />
                                            </div>

                                            <div className="content">
                                                <h3>{item['item']['name']}</h3>
                                                <p>{item['item']['symbol']}</p>

                                                <div className="bid-price">
                                                    <div className="left">
                                                        <span>Price </span>

                                                        <h4>
                                                            {
                                                                item['item']['paymentToken'] == undefined || item['item']['paymentToken'] == "0x0000000000000000000000000000000000000000"
                                                                    ?
                                                                    <span>{item['item']['price']} XDC</span>
                                                                    :
                                                                    <span>{item['item']['price']} CIFI</span>
                                                            }
                                                        </h4>
                                                    </div>

                                                    <button className="mint" onClick={() => setInstance(item['item'])}>
                                                        Mint
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

const mapDispatchToProps = { setLoader, getXRC721 };

const mapStateToProps = ({ Auth }) => {
    let { kycStatus, xrc721Collections, address } = Auth;
    return { kycStatus, xrc721Collections, address };
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);