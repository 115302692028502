import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setToken } from "../axios";
import { all, takeEvery, call, put, take } from 'redux-saga/effects';
import { setKycStatus, setKyc, kycResponse, kybResponse, setXdcPrice, setAllDonations, setDonationsNames, setXRC721, setUser, setKyb, setKybStatus } from '../actions/Auth';

function* getKycStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/getKycStatus', payload });
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKyc(response['data']['body']));
    yield put(setKycStatus(response['data']['body'][0]['status']));
  }
};

function* getKybStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/getKybStatus', payload });
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKyb(response['data']['body']));
    yield put(setKybStatus(response['data']['body']['kybStatus']));
  }
};

function* getUser({ payload }) {
  const { error, response } = yield call(postCall, { path: '/getUser', payload });
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setUser(response['data']['body']));
  }
};

function* kycSubmission({ payload }) {
  const { error, response } = yield call(postCall, { path: '/kycSubmit', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(kycResponse(response['data']['body']));
  }
};

function* getXdcPrice() {
  const { error, response } = yield call(getCall, '/getXdcPrice');
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(setXdcPrice(response['data']['body'][0]['price']));
  }
};


function* getAllDonations() {
  const { error, response } = yield call(getCall, '/getAllDonations');
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    let donationContracts = response['data']['body'];
    let names = [];
    donationContracts.map((item) => {
      let donationName = item['contractName']
      names.push(donationName);
    });
    yield put(setAllDonations(response['data']['body']));
    yield put(setDonationsNames(names));
  }
};

function* kynGET() {
  const { error, response } = yield call(getCall, '/getAllDonations');
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    let donationContracts = response['data']['body'];
    let names = [];
    donationContracts.map((item) => {
      let donationName = item['contractName']
      names.push(donationName);
    });
    yield put(setAllDonations(response['data']['body']));
    yield put(setDonationsNames(names));
  }
};

export function* kynSubmit({ payload }) {
  const { error, response } = yield call(postCall, { path: '/kynSubmit', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put(kybResponse(true));
    EventBus.publish("success", response['data']['message'])
  }
};

export function* getXRC721() {
  const { error, response } = yield call(getCall, "/getXRC721");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setXRC721(response['data']['body']));
  }
};

function* actionWatcher() {
  yield takeEvery('GET_XRC721', getXRC721);
  yield takeEvery('KYC_SUBMIT', kycSubmission);
  yield takeEvery('GET_XDC_PRICE', getXdcPrice);
  yield takeEvery('GET_KYC_STATUS', getKycStatus);
  yield takeEvery('GET_ALL_DONATIONS', getAllDonations);
  yield takeEvery('KYN_GET', kynGET);
  yield takeEvery('GET_USER', getUser);
  yield takeEvery('KYN_SUBMIT', kynSubmit);
  yield takeEvery('GET_KYB_STATUS', getKybStatus);
  
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
