import { PURGE } from "redux-persist";
import { setToken } from "../axios";

var initialState = {
  isLogin: false,
  auth: localStorage.getItem("token"),
  address: localStorage.getItem("address"),
  isLoader: { message: "Please Wait...", status: false },
  registerData: [],
  isInfoModal: false,
  kycStatus: false,
  kycValue: [],
  kynStatus: false,
  kynValue: [],
  userData: [],
  donationContracts: [],
  donationNames: [],
  xdcPrice: "",
  kycSubmitted: {},
  kynubmitted: false,
  kynSet: {},
  xrc721Collections: [],
};


const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "LOGIN":
      localStorage.setItem("token", payload);
      return {
        ...state,
        address: payload,
        auth: payload,
        isLogin: !state.isLogin,
      };

    // case 'SET_LOGIN_DATA':
    //   setToken(payload['token']);
    //   localStorage.setItem('token', payload['token']);
    //   return {
    //     ...state,
    //     auth: payload.token,
    //   };

    // case 'TOGGLE_LOGIN':
    //   return {
    //     ...state,
    //     isLogin: !state.isLogin,
    //   };

    case "IS_LOGIN_DISABLED":
      return {
        ...state,
      };

    case "LOGOUT":
      setToken();
      localStorage.removeItem("token");
      return {
        ...state,
        address: "",
        auth: "",
        isLogin: !state.isLogin,
      };

    case "SET_ADDRESS":
      localStorage.setItem("address", payload);
      return {
        ...state,
        address: payload,
      };

    case "SET_XDC_PRICE":
      return {
        ...state,
        xdcPrice: payload,
      };

    case "SET_ALL_DONATIONS":
      return {
        ...state,
        donationContracts: payload,
      };

    case "SET_DONATIONS_NAMES":
      return {
        ...state,
        donationNames: payload,
      };

    /*========== LOADER REDUCERS ============= */

    case "SET_LOADER":
      return {
        ...state,
        isLoader: payload,
      };

    /*========== PRE-REGISTER REDUCERS ============= */

    case "SET_TAX_DATA":
      return {
        ...state,
        registerData: payload,
      };

    case "TOGGLE_INFO_MODAL":
      return {
        ...state,
        isInfoModal: payload,
      };

    /*========== APPROVE DROPS REDUCERS ============= */

    case "SET_KYC_STATUS":
      return {
        ...state,
        kycStatus: payload,
      };

    case "SET_KYC":
      return {
        ...state,
        kycValue: payload,
      };

    case "SET_USER":
      return {
        ...state,
        userData: payload,
      };

    case "KYC_RESPONSE":
      return {
        ...state,
        kycSubmitted: payload,
      };

    case "KYN_SET":
      return {
        ...state,
        kynSet: payload,
      };

    case "SET_KYB_STATUS":
      return {
        ...state,
        kynStatus: payload,
      };

    case "SET_KYB":
      return {
        ...state,
        kynValue: payload,
      };

    case "KYB_RESPONSE":
      return {
        ...state,
        kynSubmitted: true,
      };


    /*========== MARKETPLACE REDUCERS ============= */

    case "SET_XRC721":
      return {
        ...state,
        xrc721Collections: payload,
      };



    default:
      return state;
  }
};

export default Auth;